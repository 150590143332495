var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-flex',{attrs:{"xs12":"","sm8":"","offset-sm2":"","mt-2":""}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('h1',{staticStyle:{"color":"#DC4C46","text-align":"center"}},[_vm._v("Leaderboard")])])],1),_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","sm10":"","offset-sm1":"","mt-3":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.standingTable.headings,"items":_vm.allScores,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticStyle:{"width":"50px"},style:({
		      		color: item.UserEmail == _vm.getuseremail ? '#DC4C46' : 'black'
		      	})},[_vm._v(_vm._s(item.Rank))]),(!item.IsVeteran)?_c('td',{style:({
		      		color: item.UserEmail == _vm.getuseremail ? '#DC4C46' : 'black'
		      	})},[_vm._v(_vm._s(item.UserName))]):_c('td',[_c('v-chip',{attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-fire ")]),_c('span',[_vm._v(_vm._s(item.UserName))])],1)],1),_c('td',{style:({
		      		color: item.UserEmail == _vm.getuseremail ? '#DC4C46' : 'black'
		      	}),attrs:{"align":"center"}},[_vm._v(_vm._s(item.Score))])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }